import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  const [tabs] = useState([
    "tv9kannada",
    "zeekannada",
    "suvarnanews",
    "news18kannada",
    "republickannada",
  ]);

  const [selectedTab, setSelectedTab] = useState<string>(
    () => localStorage.getItem("selectedTab") || "tv9kannada"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedTab(channelKey);
    localStorage.setItem("selectedTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedTab");
    if (savedTab) {
      setSelectedTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["zeekannada", "republickannada"];
  const hiddenTabs: any = [""];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const tv9kannada = `/assets/channel-logos/tv9-kannada.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const zeekannada = `/assets/channel-logos/zee-kannada.jpeg?v=${cacheBuster}`;
  const suvarnanews = `/assets/channel-logos/suvarna-news.jpeg?v=${cacheBuster}`;
  const news18kannada = `/assets/channel-logos/news18-kannada.jpeg?v=${cacheBuster}`;
  const republickannada = `/assets/channel-logos/republic-kannada.png?v=${cacheBuster}`;

  const imagesToPreload = [
    tv9kannada,
    zeekannada,
    suvarnanews,
    news18kannada,
    republickannada,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Kannada News Live | TV9 Kannada, Zee Kannada, Suvarna News, News18
            Kannada, Republic Kannada
          </title>
          <meta
            name="description"
            content="Watch live streaming of TV9 Kannada, Zee Kannada, Suvarna News, News18 Kannada, and Republic Kannada on Kannada-news.live. Stay updated with the latest Kannada news from trusted sources."
          />
          <meta
            name="keywords"
            content="Kannada news live, TV9 Kannada live, Zee Kannada live, Suvarna News live, News18 Kannada live, Republic Kannada live, Kannada breaking news, Kannada news channels, live news streaming Kannada"
          />
        </Helmet>
        <Header />

        {selectedOption === "Kannada" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("tv9kannada") && (
                  <div
                    className={`tab-item ${
                      selectedTab === "tv9kannada" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("tv9kannada")}
                  >
                    {/**** When you add new logo change the src={tv9kannada} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9kannada}
                        alt="TV9 Kannada Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={indiatoday} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("zeekannada") && (
                  <div
                    className={`tab-item ${
                      selectedTab === "zeekannada" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("zeekannada")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeekannada}
                        alt="Zee Kannada Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("suvarnanews") && (
                  <div
                    className={`tab-item ${
                      selectedTab === "suvarnanews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("suvarnanews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={suvarnanews}
                        alt="Suvarna News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18kannada") && (
                  <div
                    className={`tab-item ${
                      selectedTab === "news18kannada" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("news18kannada")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18kannada}
                        alt="News18 Kannada Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("republickannada") && (
                  <div
                    className={`tab-item ${
                      selectedTab === "republickannada" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("republickannada")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={republickannada}
                        alt="Republic Kannada Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedTab === "tv9kannada" &&
                visibleTabs.includes("tv9kannada") && (
                  <>
                    <h1>TV9 Kannada Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/jdJoOhqCipA?si=wemgrmpUJKdfC1ZD&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Kannada Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedTab === "zeekannada" &&
                visibleTabs.includes("zeekannada") && (
                  <>
                    <h1>Zee Kannada Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/kr-YIH866cM?si=K1C6nuS6jWHji-Nv&autoplay=1&v=${cacheBuster}`}
                      title="Zee Kannada Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTab === "suvarnanews" &&
                visibleTabs.includes("suvarnanews") && (
                  <>
                    <h1>Suvarna News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/R50P2knCQBs?si=kTlYfNyL4nHRHVIn&autoplay=1&v=${cacheBuster}`}
                      title="Suvarna News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTab === "news18kannada" &&
                visibleTabs.includes("news18kannada") && (
                  <>
                    <h1>News18 Kannada Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ztw2nX78cMw?si=tKFkIYMk9T23BnOA&autoplay=1&v=${cacheBuster}`}
                      title="News18 Kannada Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedTab === "republickannada" &&
                visibleTabs.includes("republickannada") && (
                  <>
                    <h1>Republic Kannada Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/CiehbjVwMAs?si=PQnTfGkvxCvWUaPN&autoplay=1&v=${cacheBuster}`}
                      title="Republic Kannada Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
