import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Privacy Policy | Kannada News Live | TV9 Kannada, Zee Kannada,
            Suvarna News, News18 Kannada, Republic Kannada
          </title>
          <meta
            name="description"
            content="Read the privacy policy for www.kannada-news.live. Learn about our data collection practices, cookie usage, and the third-party content we provide. Watch live Kannada news from trusted channels."
          />
          <meta
            name="keywords"
            content="Privacy policy, Kannada news live, TV9 Kannada live, Zee Kannada live, Suvarna News live, News18 Kannada live, Republic Kannada live, data protection, cookie policy, third-party links"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to Kannada News Live, accessible at www.kannada-news.live.
            Your privacy is important to us. This Privacy Policy outlines how we
            collect, use, and protect your information when you visit our
            website.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <ul className="dash-list">
                <li>
                  Personal Information: We do not collect any personal
                  information unless you voluntarily provide it through our
                  contact form.
                </li>
                <li>
                  Non-Personal Information: We may collect non-personal data
                  such as your browser type, IP address, and user interaction
                  data to improve your experience on our site.
                </li>
              </ul>
            </li>
            <li>
              <h2>Third-Party Content and Ownership</h2>
              <p>
                Our website features{" "}
                <h3>live streaming links to various Kannada news channels</h3>{" "}
                through embedded YouTube videos, including:
              </p>
              <ul className="dash-list">
                <li>
                  TV9 Kannada Live - [Watch here] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=jdJoOhqCipA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=jdJoOhqCipA
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Zee Kannada Live - [Watch here] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=kr-YIH866cM"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=kr-YIH866cM
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Suvarna News Live - [Watch here] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=R50P2knCQBs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=R50P2knCQBs
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  News18 Kannada Live - [Watch here] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=ztw2nX78cMw"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=ztw2nX78cMw
                    </a>
                  </h3>
                  )
                </li>
                <li>
                  Republic Kannada Live - [Watch here] (
                  <h3>
                    <a
                      href="https://www.youtube.com/watch?v=CiehbjVwMAs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.youtube.com/watch?v=CiehbjVwMAs
                    </a>
                  </h3>
                  )
                </li>
              </ul>
              <h3>
                We do not claim ownership of the content or videos embedded on
                our site. All rights belong to their respective owners.
              </h3>
            </li>

            <li>
              <h2>Cookies</h2>
              <p>
                We may use cookies to enhance your browsing experience. These
                cookies collect anonymous information and help us understand how
                visitors interact with our website.
              </p>
            </li>
            <li>
              <h2> Data Protection</h2>
              <p>
                We prioritize the security of your data. Our website is secured
                with industry-standard measures to protect any information
                collected from unauthorized access.
              </p>
            </li>
            <li>
              <h2>External Links</h2>
              <p>
                Our website may contain links to third-party websites (YouTube).
                Once you click on these links, you will be subject to the
                privacy policies of those sites. We encourage you to review
                their policies.
              </p>
            </li>
            <li>
              <h2>Changes to This Privacy Policy</h2>
              <p>
                We may update this privacy policy from time to time. Any changes
                will be posted on this page with an updated effective date.
              </p>
            </li>
            <li>
              <h3>Contact Us</h3>
              <p>
                If you have any questions or concerns regarding this privacy
                policy, please contact us at contact@kannada-news.in.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
